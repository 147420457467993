import * as React from "react"
import loadable from '@loadable/component'
import PropTypes from "prop-types"
import './layout.css'
import AppNavBar from './UI/Navbar-pages'
import Footer from './UI/footer'

const Landing = loadable(() => import('./UI/landing-image'))

const Layout = ({ children }) => {

  return (
    <>
      <AppNavBar>
        <Landing />
      </AppNavBar>
      <div className="main-section">
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}


Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
