import React from 'react'
import styled from 'styled-components'
import { LinkedInIcon, TwitterIcon, InstagramIcon } from './Buttons'

const ShareBox = styled.div `
    text-align: center;
    margin: 15vh auto;
    
    @media (max-width: 768px) {
      margin: 0 1em 15vh 1em;
    }
`

const Icons = styled.div `
margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
 
  & * {
    fill: #000;
    width: 100%;

    &:hover {
      fill: var(--tuva-orange-color);
      transition: 0.3s
    }
  }

  @media screen and (max-width: 768px) {
    & * {
      width: 80%;
    }
`

export default function ShareBanner() {
    return (
        <ShareBox>
            <h1>follow us on social</h1>
            <Icons>
                <LinkedInIcon />
                <TwitterIcon />
                <InstagramIcon />
            </Icons>
        </ShareBox>
    )
}
