import React from 'react'
import { PageButton } from "./Buttons"

 const ActionButton = (props) => {
    return (
        <div className="call-to-action">
            <h3 style={{ paddingRight: '1em' }}>
                {props.children}
            </h3>
            <PageButton styleName="black" linkRef={props.link}>
                {props.button}
            </PageButton>
        </div>
    )
}

export default ActionButton;