import * as React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Layout from "../components/layout"
import { LinkedInButton } from "../components/UI/Buttons"
import ShareBanner from "../components/UI/share-banner"
import Colors from "../components/UI/colors"
import ActionButton from "../components/UI/call-to-action"

const Wrapper = styled.div`
  display: grid;
  overflow: hidden;
  justify-content: space-evenly;
  align-items: center;
  grid-template-columns: 1fr 1fr;

  .content-block {
    margin: auto;
    padding-top: 10vh;
    padding-bottom: 10%;
    display: block;
    width: 70%;
    height: 100%;

    h1 {
      text-align: center;
    }
  }

  .image-block {
    position: relative;

    p {
      position: absolute;
      font-style: italic;
      bottom: 60px;
      text-align: right;
      left: 360px;
      width: 25%;
    }
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column-reverse;

    .image-block {
      p {
        display: none;
      }
    }
  }
`

const TeamGrid = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 2em;
  margin: 2vh 2%;

  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const Card = styled.div`
  width: 340px;
  height: fit-content;
  filter: var(--tuva-drop-shadow-filter);

  > * {
    color: #000;
  }

  .head-image {
    margin: auto 0 0 auto;
    width: 80%;
  }

  .background-image {
    position: relative;
    height: fit-content;
    min-height: 200px;
  }

  .background-frame {
    position: absolute;
    left: 0;
    top: 0;
    padding: 1px;
    margin: -1px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    clip-path: polygon(0 10%, 70% 100%, 100% 60%, 100% 100%, 0 100%);
    z-index: 0;
  }

  .content {
    position: relative;
    display: flex;
    background-color: #fff;
    padding: 12px;
    flex-direction: column;
    justify-content: space-between;
    z-index: 2;

    > * {
      margin: 2px;
    }
  }

  @media (max-width: 1024px) {
    margin: 1em 0;
    max-width: 310px;
  }
`

const AboutUs = ({ data }) => {
  const page = data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  const about = [page.what_we_do, page.who_we_are]
  const team = data.allMarkdownRemark.edges

  // Generate the two opening paragraphs
  const ListElement = Object.values(about)
    .reverse()
    .map((about, i) => {
      const isLeft = i % 2 === 1

      return (
        <Wrapper key={i}>
          <div className="image-block">
            <GatsbyImage
              image={about.image.childImageSharp.gatsbyImageData}
              alt={"" + about.tag + ""}
            />
            <p>{about.tag}</p>
          </div>
          <div
            className="content-block"
            style={isLeft ? { gridRow: "2 / 1" } : { gridRow: "2 / 0" }}
          >
            <h1>{about.title}</h1>
            <div
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{ __html: about.body }}
            />
          </div>
        </Wrapper>
      )
    })

  //Generate the Team cards from CMS

  const TeamCard = team.map(({ node }) => {
    const { image, name, title, link, color } = node.frontmatter
    const html = node.html

    let bColor
    switch (color) {
      case "orange":
        bColor = Colors.OrangeGrad
        break
      case "blue":
        bColor = Colors.BlueGrad
        break
      case "green":
        bColor = Colors.GreenGrad
        break
      case "purple":
        bColor = Colors.PurpleGrad
        break
      case "turqoise":
        bColor = Colors.TurqGrad
        break
      case "red":
        bColor = Colors.RedGrad
        break
      default:
        throw new Error(`Invalid color: ${color}`)
    }

    return (
      <Card key={name} style={{ background: bColor }}>
        <div className="background-image">
          <GatsbyImage
            className="head-image"
            image={image.childImageSharp.gatsbyImageData}
            alt={name}
          />
          <div className="background-frame"></div>
        </div>
        <div className="content">
          <h4>{name}</h4>
          <h3>{title}</h3>
          <p
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <div style={{ alignSelf: "flex-end" }}>
            <LinkedInButton linkRef={link} />
          </div>
        </div>
      </Card>
    )
  })

  return (
    <Layout>
      <Seo title="About" />

      {ListElement}
      <ActionButton button="Learn More" link="/services">
        {page.call_about}
      </ActionButton>

      <div style={{ textAlign: "center", margin: "5vh auto" }}>
        <h1>the tuva senior team</h1>
      </div>
      <TeamGrid>{TeamCard}</TeamGrid>
      <ShareBanner />
    </Layout>
  )
}

export const query = graphql`
  {
    allFile(filter: { name: { eq: "about" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              call_about
              what_we_do {
                body
                tag
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                title
              }
              who_we_are {
                body
                tag
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                title
              }
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { cms: { eq: "team" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            cms
            color
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [PNG, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
            }
            name
            link
            title
          }
          html
        }
      }
    }
  }
`

export default AboutUs
