

const OrangeGrad = 'linear-gradient(90deg, #ffbc00 -17.4%, #f78d1e 107.16%)'


const BlueGrad = 'linear-gradient(90deg, #27ddfd 23.72%, #6e8bfc 93.75%)'


const GreenGrad = 'linear-gradient(90deg, #22d59c 19.89%, #179d84 81.37%)'


const PurpleGrad = 'linear-gradient(90deg, #ce0fff 15.37%, #63398e 85.92%)'


const TurqGrad = 'linear-gradient(90deg, #01becf 14.96%, #6301ff 85.12%)'


const RedGrad = 'linear-gradient(90deg, #ff3917 14.69%, #e61b72 78.83%)'


const Colors = {
    OrangeGrad: OrangeGrad,
    BlueGrad: BlueGrad,
    GreenGrad: GreenGrad,
    PurpleGrad: PurpleGrad,
    TurqGrad: TurqGrad,
    RedGrad: RedGrad
}

export default Colors